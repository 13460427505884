var vueThis = null
export function receive(vm) {
  vueThis = vm
}

var baseURL = window.location.protocol + '//' + window.location.host;
function addScript(url){
  var script = document.createElement('script');
  script.setAttribute('type','text/javascript');
  script.setAttribute('src',url);
  document.getElementsByTagName('head')[0].appendChild(script);
}
addScript('https://oss.tiantianhuoke.com/static/diy-view/js/md5.js')
import moment from 'moment'
import E from 'wangeditor'
import hljs from 'highlight.js'
import axios from 'axios'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import qiunVueUcharts from '@qiun/vue-ucharts'
/* 全局方法开始 */
function guid(len = 32, firstU = true, radix = null) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  var uuid = []
  radix = radix || chars.length

  if (len) {
    // 如果指定uuid长度,只是取随机的字符,0|x为位运算,能去掉x的小数位,返回整数位
    for (var i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
  } else {
    var r
    // rfc4122标准要求返回的uuid中,某些位为固定的字符
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    for (var i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16)
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  // 移除第一个字符,并用u替代,因为第一个字符为数值时,该guuid不能用作id或者class
  if (firstU) {
    uuid.shift()
    return `u${uuid.join('')}`
  }
  return uuid.join('')
}
function downloadIamge (imgsrc, name) {
  //下载图片地址和图片名
  var image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    var a = document.createElement("a"); // 生成一个a元素
    var event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}
function installImage (imgsrc, name) {
  downloadIamge(imgsrc, name || guid(8))
}
//将base64转换为文件对象
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  //转换成file对象
  return new File([u8arr], filename, { type: mime });
  //转换成成blob对象
  //return new Blob([u8arr],{type:mime});
}
//压缩图片方法 http://t.zoukankan.com/MainActivity-p-9359400.html
function compressImg(file, callback) {
  var src;
  var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2);
  var read = new FileReader();
  read.readAsDataURL(file);
  read.onload = function (e) {
    var img = new Image();
    img.src = e.target.result;
    img.onload = function () {
      //默认按比例压缩
      var w = this.width,
        h = this.height;
      //生成canvas
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var base64;
      // 创建属性节点
      canvas.setAttribute("width", w);
      canvas.setAttribute("height", h);
      ctx.drawImage(this, 0, 0, w, h);
      if (fileSize < 1) {
        //如果图片小于一兆 那么不执行压缩操作
        base64 = canvas.toDataURL(file['type'], 0.5);
      } else if (fileSize > 1 && fileSize < 2) {
        //如果图片大于1M并且小于2M 那么压缩0.5
        base64 = canvas.toDataURL(file['type'], 0.5);
      } else {
        //如果图片超过2m 那么压缩0.2
        base64 = canvas.toDataURL(file['type'], 0.2);
      }
      // 回调函数返回file的值
      callback(base64);
    };
  };
}
//将文件转换为base64
function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
function getUploadToOSSOptions (successFn,errorFn){
  $.ajax({
    url: baseURL + `/index/oss/get?memberid=${window.member_id || '1899'}`,
    data: {},
    type: "GET",
    success: function (res) {
      var obj = JSON.parse(res); //接收请求数据
      successFn(obj)
    },
    error(err) {
      if (typeof errorFn == 'function') errorFn(err)
    }
  });
}
function dateQuickSelect (type,formatData) {
  var now = new Date(); //当前日期
  var nowDayOfWeek = now.getDay(); //今天本周的第几天
  var nowDay = now.getDate(); //当前日
  var nowMonth = now.getMonth(); //当前月
  var nowYear = now.getFullYear(); //当前年
  var jd=Math.ceil((nowMonth + 1) / 3);
  var startTim = function(newDate) {	//newDate等于日期类型
    var nowTimeDate = newDate
    return moment(new Date(nowTimeDate.setHours(0, 0, 0, 0))).format(formatData)
  }
  var endTime = function(newDate) {	//newDate等于日期类型
    var nowTimeDate = newDate
    return moment(new Date(nowTimeDate.setHours(23, 59, 59, 999))).format(formatData)
  }
  var range =[]
  switch (type) {
    case 'today':
      range = [moment(new Date(new Date().toLocaleDateString()).getTime()).format(formatData) , moment(new Date((new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1))).format(formatData)]
      break;
    case 'yesterday':
      range = [startTim(new Date(new Date().setDate(new Date().getDate() - 1))), endTime( new Date(new Date().setDate(new Date().getDate() - 1)) )]
      break;
    case 'current_week':
      range = [startTim(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek+1)), endTime(new Date(nowYear, nowMonth, nowDay + 7 - nowDayOfWeek))]
      break;
    case 'last_week':
      range = [startTim(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek-6)), endTime(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek))]
      break;
    case 'current_month':
      range = [ startTim(new Date(nowYear, nowMonth, 1)), endTime(new Date(nowYear, nowMonth + 1, 0))]
      break;
    case 'lastmonth':
      range = [ startTim( new Date(nowYear, nowMonth-1, 1)), endTime( new Date(nowYear, nowMonth, 0))]
      break;
    case 'current_season':
      range = [ startTim( new Date(nowYear, (jd-1)*3, 1) ) , endTime(new Date(nowYear, jd*3, 0))]
      break;
    case 'last_season':
      range = [ startTim( new Date(nowYear, (jd-2)*3, 1) ) , endTime(new Date(nowYear, (jd-1)*3, 0))]
      break;
    case 'season_Q1':
      range = [ startTim( new Date(nowYear, 0*3, 1) ) , endTime(new Date(nowYear, 1*3, 0))]
      break;
    case 'season_Q2':
      range = [ startTim( new Date(jd<2 ? nowYear-1 : nowYear, 1*3, 1) ) , endTime(new Date(jd<2 ? nowYear-1 : nowYear, 2*3, 0))]
      break;
    case 'season_Q3':
      range = [ startTim( new Date(jd<3 ? nowYear-1 : nowYear, 2*3, 1) ) , endTime(new Date(jd<3 ? nowYear-1 : nowYear, 3*3, 0))]
      break;
    case 'season_Q4':
      range = [ startTim( new Date(jd<4 ? nowYear-1 : nowYear, 3*3, 1) ) , endTime(new Date(jd<4 ? nowYear-1 : nowYear, 4*3, 0))]
      break;
    case 'current_year':
      range = [startTim( new Date(nowYear, 0, 1) ), endTime(new Date(nowYear, 11, 31)) ]
      break;
    case 'yesteryear':
      range = [startTim( new Date(nowYear-1, 0, 1) ), endTime(new Date(nowYear-1, 11, 31)) ]
      break;

    default:
      break;
  }
  return range
}
export function getUrlKey(name) {
  var {params,query} = vueThis.$route; params = Object.assign(params, query)
  return params[name] || undefined
}
export function dataToTime (filed,type,format) {
  var arr = dateQuickSelect(type,format)
  filed = arr.length > 1 ? arr.join(' - ') : ''
}
var isJsonString = (str) => {
  try {
    if (typeof JSON.parse(str) == 'object') {
      return true
    }
  } catch (e) {}
  return false
}
export var isNotArray = (arg) => {
  if (!Array.isArray) {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
export function constructionData (params) {
  if (!params) return []
  if (isNotArray(params)) {
    return params
  } else {
    if (isJsonString(params)) {
      return JSON.parse(params)
    } else {
      return [params]
    }
  }
}
export function treeFindPath (tree, func, props, path = []) {
  if (!tree) return []
  for (var data of tree) {
    path.push(data[props.value])
    if (func(data)) return path
    if (data[props.children]) {
      var findChildren = treeFindPath(data[props.children], func, props, path)
      if (findChildren.length) return findChildren
    }
    path.pop()
  }
  return []
}
export function themeColorInit({themeConfig, themeType}) {
  if(!themeConfig) return ''
  var projectConfig = themeConfig[themeType]
  var themeColor = ''
  projectConfig.colorConfig.map((item, index) => {
    themeColor += `--benbenFontColor${index}:${item};`
  })
  projectConfig.bgColorConfig.map((item, index) => {
    themeColor += `--benbenbgColor${index}:${item};`
  })
  projectConfig.bdColorConfig.map((item, index) => {
    themeColor += `--benbenbdColor${index}:${item};`
  })
  return themeColor
}
export function clearDataPass (data){
  var param = data
  switch (dataTypeJudge(param)) {
    case 'String':
      data = ''
      break;
    case 'Number':
      data = 0
      break;
    case 'Boolean':
      data = false
      break;
    case 'Array':
      data = []
      break;
    case 'Object':
      Object.keys(param).map(key => {
        var item = data[key]
        data[key] = dataTypeJudge(item)=='String'?'':dataTypeJudge(item)=='Number'?0:dataTypeJudge(item)=='Boolean'?false:dataTypeJudge(item)=='Array'?[]:{}
      })
      break;
    default:
      break;
  }
}
export function closePagePass(){

}
// 判断是否多值是否成立
export function in_array(type, str) {
  var arr = []
  // str 是否为字符串
  if (typeof str === 'string') arr = str.split(',')
  // str 是否为数组
  if (typeof str === 'object') arr = str
  var index = -1
  index = arr.findIndex(item => type == item)
  console.log('index-------------', index);
  if (index == -1) {
    return false
  }
  return true
}
function mixinCopyData (data) {
  return JSON.parse(JSON.stringify(data))
}
export function mixinAddTableItem(arr,index) {
  var source = optiopsFiler(arr)
  if(index!==0&&!index){
    index = source.length>0?source.length-1:0
  }
  var copyItem =source[index]
  var item = (isJsonString(copyItem)|| Object.prototype.toString.call(copyItem)=='[object Object]')?mixinCopyData(copyItem):''
  source.push(item);
}
export function mixinTableItemChange(index,value,filed,arr) {
  var that = vueThis
  var source = optiopsFiler(arr)
  var item = source[index]
  item[filed] = value
  that.$set(source,index,item)
}
function optiopsFiler(list) {
  list = list ? list.split('.') : []
  if (list.length) {
    var option = vueThis[list[0]]
    for (var i = 1; i < list.length; i++) {
      option = option && option[list[i]]
    }
    return option
  }
  return []
}
export function dataTypeJudge(param){
  if(param===null){
    return 'Null'
  } else if((typeof param) ==='undefined'){
    return 'Undefined'
  }  else if((typeof param) =='string'){
    return 'String'
  } else if((typeof param) =='number'){
    return 'Number'
  } else if((typeof param) =='boolean'){
    return 'Boolean'
  }else if((typeof param) =='object'){
    if(Object.prototype.toString(param)=='[object Array]'){
      return 'Array'
    } else if(Object.prototype.toString(param)=='[object Object]'){
      return 'Object'
    }
  }
}
export function isEmpty (val){
  if (dataTypeJudge(val)=='Array'||dataTypeJudge(val)=='String') return val.length === 0

  if (dataTypeJudge(val)=='Object') return Object.keys(val).length === 0

  if (dataTypeJudge(val)=='Null'||dataTypeJudge(val)=='Undefined') return true

  if (dataTypeJudge(val) =='Number' && Number.isNaN(val)) return true

  return false
}
export function copyText (text) {
  var target = document.createElement('input') //创建input节点
  target.value = text // 给input的value赋值
  document.body.appendChild(target) // 向页面插入input节点
  target.select() // 选中input
  try {
    document.execCommand('Copy') // 执行浏览器复制命令
    return message.info('复制成功')
  } catch {
    return message.info('该浏览器不支持自动复制')
  }
  target.remove() // 删除input节点
}
// 判断是否在角色权限列表内
export function in_Roles (role,rolesList) {
  var flag = false
  role=role.split(",")
  rolesList=rolesList.split(",")
  role.map((item)=>{
    if(rolesList.indexOf(item)>-1){
      flag = true
    }
  })
  return flag
}
/* 全局方法结束 */

export var switchForm =  {
  template: ` <a-switch :size="size" :disabled="disabled" @change="handleChange" v-model="checked" />`,
  name: 'switch-form',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      checked: false
    }
  },
  watch: {
    value: {
      handler(val) {
        this.checked = val == 1 ? true : false
      },
      immediate: true
    }
  },
  computed: {},
  methods: {
    handleChange() {
      var val = this.checked ? 1 : 0
      console.log(val)
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
var checkboxForm = {
  template: `<div><a-checkbox v-if="checkAll" :indeterminate="indeterminate" :checked="checkAllData" @change="onCheckAllChange">
  全选 
</a-checkbox> <a-checkbox-group   :disabled='disabled'  @change="handleChange"  v-model = 'checkeds'>
  
<a-checkbox v-for="(option, i) in options" :key="i" :value="option[fieldNames.value]">{{option[fieldNames.label]}}</a-checkbox>
</a-checkbox-group></div>`,
  name: 'checkbox-form',
  props: {
      disabled: {
          type: Boolean,
          default: false
      },
      options: {
          type: Array,
          default: []
      },
      value: {
          type: String,
          default: ''
      },
      checkAll: {
        type: Boolean,
        default: false
      },
      fieldNames: {
        type: Object,
        default: () => ({ label: 'name', value: 'aid', children: 'child' })
      }
  },
  data() {
      return {
        indeterminate: true,
        checkeds: [],
        checkAllData:false
      }
  },
  watch: {
      value: {
          handler(val) {
              this.checkeds = val ? (val + '').split(',') : []
              this.checkAllData = this.checkeds.length === this.options.length
              return
          },
          immediate: true
      }
  },
  computed: {
    plainOptions(){
      var arr = []
      this.options.map((item)=>{
         arr.push(item[this.fieldNames.value])
      })
      return arr
    }
  },
  methods: {
    onCheckAllChange(e) {
        this.checkeds= e.target.checked ? this.plainOptions : []
        this.indeterminate= false
        this.checkAllData= e.target.checked
    },
    handleChange() {
        var arr = []
        arr = this.checkeds
        this.indeterminate = this.checkeds.length && this.checkeds.length < this.plainOptions.length;
        this.checkAllData = this.checkeds.length === this.options.length;
        arr = arr.length == 1 ? arr[0] : arr.length == 0 ? '' : arr.join(',')
        this.$emit('input', arr)
        this.$emit('change', arr)
    }
  }
}
export var multipleSelect = {
  template: ` <a-select
  v-model="checkeds"
  :mode="mode"
  :disabled="disabled"
  :allow-clear="allowClear"
  :placeholder="placeholder"
  :filter-option="filterOption"
  option-filter-prop="children"
  @change="handleChange" 
>
<a-select-option v-for="(option, i) in options" :key="option[fieldNames.value]+''" :label="option[fieldNames.label]">
{{option[fieldNames.label]}}
</a-select-option>
</a-select>`,
  name: 'multiple-select',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: []
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    mode: {
      type: String,
      default: 'multiple'
    },
    value: {
      type: String,
      default: ''
    },
    fieldNames: {
      type: Object,
      default: () => ({ label: 'name', value: 'aid', children: 'child' })
    }
  },
  data() {
    return {
      checkeds: []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.checkeds = val ? (val + '').split(',') : []
        return
      },
      immediate: true
    }
  },
  computed: {},
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange() {
      var arr = []
      arr = this.checkeds
      arr = arr.length == 1 ? arr[0] : arr.length == 0 ? '' : arr.join(',')
      this.$emit('input', arr)
      this.$emit('change', arr)
    }
  }
}
export var simpleSelect = {
  template: ` <a-select
  show-search
  v-model="checked"
  :disabled="disabled"
  :allow-clear="allowClear"
  :placeholder="placeholder"
  :filter-option="filterOption"
  @change="handleChange" 
>
  <a-select-option v-for="(option, i) in options" :key="option[fieldNames.value]+''" :label="option[fieldNames.label]">
  {{option[fieldNames.label]}}
  </a-select-option>
</a-select>`,
  name: 'simple-select',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: []
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    value: {
      type: [String,Number],
      default: ''
    },
    fieldNames: {
      type: Object,
      default: () => ({ label: 'name', value: 'aid', children: 'child' })
    }
  },
  data() {
    return {
      checked: ''
    }
  },
  watch: {
    value: {
      handler(val) {
        this.checked = val||undefined
        return
      },
      immediate: true
    }
  },
  computed: {},
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(e) {
      this.$emit('input', e)
      this.$emit('change', e)
    }
  }
}
export var benbenSelectDiy = {
  template: ` <div :class="className">
  <slot></slot>
  </div>`,
  name: 'benben-select-diy',

  options: {
    virtualHost: true,
  },
  props: {
    className: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'radio',
    },
    maxSelect: {
      type: Number,
      default: -1,
    },
    items: {
      type: Array,
      default: () => [],
    },
    defaultType: {
      type: String,
      default: 'value',
    },
    defaultLabel: {
      type: String,
      default: 'name',
    },
    allowCancel: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectArr: [],

    labelArr: [],
  }),

  computed: {},

  //监听value
  watch: {
    value: {
      handler(val) {
        if (!this.items.length) return
        let items = JSON.parse(JSON.stringify(this.items))
        let flag = false
        let selectLab = []
        if (this.type == 'checkbox') {
          let selectArr = val ? (val + '').split(',') : []
          let selectLgh = 0
          items.forEach((item) => {
            let index = selectArr.findIndex((id) => id == item[this.defaultType])

            if (index != -1) {
              selectLab.push(item[this.defaultLabel])
              item.isSelected = true
              selectLgh++
            } else {
              item.isSelected = false
            }
          })
          flag = selectLgh == this.items.length
        } else {
          items.forEach((item) => {
            if (item[this.defaultType] == val) {
              selectLab.push(item[this.defaultLabel])
              item.isSelected = true
            } else {
              item.isSelected = false
            }
          })
        }
        this.$emit('update:label', selectLab.join(','))
        this.$emit('update:selectAll', flag)
        this.$emit('update:items', items)
      },

      immediate: true,
    },
  },

  methods: {
    checkAll(flag = this.selectAll) {
      let selectId = ''
      let selectLab = ''
      if (this.disabled) {
        return
      }
      let selectArr = []
      let labelArr = []
      if (!flag) {
        let items = JSON.parse(JSON.stringify(this.items))
        items.forEach((item) => {
          item.isSelected = true
          selectArr.push(item[this.defaultType])
          labelArr.push(item[this.defaultLabel])
        })
        selectId = selectArr.join(',')
        selectLab = labelArr.join(',')
      }
      this.$emit('input', selectArr.join(','))
      this.$emit('change', {
        label: selectId,
        value: selectLab,
      })
    },
    tapHandle(index) {
      if (this.disabled) {
        return
      }
      let item = this.items[index]
      let selectId = ''
      let selectLab = ''
      let flag = false
      if (this.type == 'checkbox') {
        let selectArr = this.value ? (this.value + '').split(',') : []
        let labelArr = this.label ? (this.label + '').split(',') : []
        let index = selectArr.findIndex((id) => id == item[this.defaultType])
        let labelindex = labelArr.findIndex((id) => id == item[this.defaultLabel])
        if (index != -1 && this.allowCancel) selectArr.splice(index, 1)
        if (labelindex != -1 && this.allowCancel) labelArr.splice(labelindex, 1)
        if (index == -1) selectArr.push(item[this.defaultType])
        if (labelindex == -1) labelArr.push(item[this.defaultLabel])
        flag = selectArr.length == this.items.length
        if (this.maxSelect != -1 && this.maxSelect < selectArr.length) return
        selectId = selectArr.join(',')
        selectLab = labelArr.join(',')
      } else {
        if (this.allowCancel) {
          selectId = item[this.defaultType] === this.value ? '' : item[this.defaultType]
          selectLab = item[this.defaultLabel] === this.label ? '' : item[this.defaultLabel]
        } else {
          selectId = item[this.defaultType]
          selectLab = item[this.defaultLabel]
        }
      }
      this.$emit('input', selectId)
      this.$emit('update:label', selectLab)
      this.$emit('update:selectAll', flag)
      this.$emit('change', {
        label: selectId,
        value: selectLab,
      })
      return
    },
    isSelected(val) {
      if (this.type == 'checkbox') {
        return this.selectArr.findIndex((item) => item === val) != -1
      } else {
        return this.value === val
      }
    },
  },

  // 组件周期函数--监听组件挂载完毕

  mounted() {},
}
export var benbenFlexNumberBox = {
  template:` 
  <div :class="className" class="number-box">
    <span @click.stop="_calcValue('minus')" :class="minDisabled?'disabledBtn':''">
       <slot name="minus"></slot>
    </span>
    <a-input :class="inputClass" :disabled="disabled" :value="inputValue" type="number" @change="changeValue" />
    <span @click.stop="_calcValue('plus')" :class="maxDisabled?'disabledBtn':''">
       <slot name="plus"></slot>
    </span>
  </div>`,
  name: 'benben-flex-number-box',
  props: {
    value: {
      type: [Number, String],
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    step: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass:{
      type: String,
      default: 'inputid',
    },
    className:{
      type: String,
      default: '',
    },
  },
  data: () => ({
    inputValue: 0,
    timer: null,
  }),
  computed: {
    minDisabled () {
      return this.inputValue <= this.min || this.disabled
    },
    maxDisabled () {
      return this.inputValue >= this.max || this.disabled
    }
  },
  //监听value
  watch: {
    value: {
      handler(val) {
        this.inputValue = +val
      },
      immediate: true,
    },
  },
  mounted(){
  },
  methods:{
    _calcValue(type) {
      if (type === 'minus' && !this.disabled && this.inputValue == this.min) return
      if (this.disabled) return
      if (this.max < this.inputValue) {
        this.inputValue = this.max
      }
      var value = this.inputValue
      var step = this.step
      if (type === 'minus') {
        value -= step
        if (value < this.min) return
        if (value > this.max) {
          value = this.max
        }
      } else if (type === 'plus') {
        value += step
        if (value > this.max) return
        if (value < this.min) {
          value = this.min
        }
      }
      this.inputValue = value
      this.$emit('input', parseInt(Number(value)))
    },
    changeValue(e) {
      var value = e.target.value
      if (!value) {
        this.inputValue = this.min
        return
      }
      if (value > this.max) {
        value = this.max
      } else if (value < this.min) {
        value = this.min
      }
      this.inputValue = parseInt(Number(value))
      this.$emit('input', this.inputValue)
    },
  }
}
export var rangePicker = {
  template: `<a-range-picker ref="rangPick" :input-read-only="true" :allow-clear="allowClear" :value-format="valueFormat" :format="format" :show-time="showTime"  :disabled='disabled'  @change="handleChange"  v-model = 'checkeds'>
  <div slot="renderExtraFooter">
    <div class="footer-btns" :class="showTime?'time-range':''" @click="clickQuickSelect">
      <span data-type="today" class="btns-today">今天</span>
      <span data-type="yesterday" class="btns-yesterday">昨天</span>
      <span data-type="current_week" class="btns-current_week">本周</span>
      <span data-type="last_week" class="btns-last_week">上周</span>
      <span data-type="current_month" class="btns-current_month">本月</span>
      <span data-type="lastmonth" class="btns-lastmonth">上月</span>
      <span data-type="current_season" class="btns-current_season">本季度</span>
      <span data-type="last_season" class="btns-last_season">上季度</span>
      <span data-type="season_Q1" class="btns-season_Q1">上年Q1</span>
      <span data-type="season_Q2" class="btns-season_Q2">上年Q2</span>
      <span data-type="season_Q3" class="btns-season_Q3">上年Q3</span>
      <span data-type="season_Q4" class="btns-season_Q4">上年Q4</span>
      <span data-type="current_year" class="btns-current_year">今年</span>
      <span data-type="yesteryear" class="btns-yesteryear">去年</span>
      <span @click.stop="checkeds=[]" class="btns-clear">清空</span>
    </div>
  </div>
  </a-range-picker>`,
  name: 'range-picker',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'default'
    },
    valueFormat:{
      type: String,
      default: "YYYY-MM-DD"
    },
    format:{
      type: String,
      default: "YYYY-MM-DD"
    },
    showTime: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checkeds: []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.checkeds = val ? (val + '').split(' - ') : []
        return
      },
      immediate: true
    }
  },
  computed: {},
  methods: {
    handleChange() {
      var arr = []
      arr = this.checkeds
      arr = arr.length > 1 ? arr.join(' - ') : ''
      this.$emit('input', arr)
      this.$emit('change', arr)
      console.log(arr,'arrr')
    },
    clickQuickSelect(e){
      this.dType = e.target.dataset.type
      if(this.dType){
        this.checkeds = dateQuickSelect(this.dType,this.format)
        var arr = []
        arr = this.checkeds
        arr = arr.length > 1 ? arr.join(' - ') : ''
        this.$emit('input', arr)
        this.$emit('change', arr)
        console.log(arr,'arrr9999')
      }
    }
  }
}
export var cascaderForm = {
  template: `<a-cascader
ref="cascader"
v-model="cascaderValue"
@change="handleChange"
:options="newOptions"
:placeholder="placeholder"
:disabled="disabled"
:size="size"
v-bind="isShowSearch(showSearch)"
:allowClear="allowClear"
:expandTrigger="expandTrigger"
:changeOnSelect="changeOnSelect"
></a-cascader>`,
  name: 'cascader-form',
  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    expandTrigger: {
      type: String,
      default: "click"
    },
    size: {
      type: String,
      default: "default"
    },
    changeOnSelect: {
      type: Boolean,
      default: false
    },
    fieldNames: {
      type: Object,
      default: () => ({ label: 'label', value: 'value', children: 'children' })
    },
    options: {
      type: Array,
      default: []
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cascaderValue: []
    }
  },
  watch: {
    value: {
      handler(val) {
        var arr = []
        if (val === null || val === '[]' || val === '' || val === undefined) {
          return this.cascaderValue = []
        }
        var valueArray
        try {
          valueArray = typeof val === 'object' ? val : JSON.parse(val)
        } catch (error) {
          valueArray = val.split(',').map(i => String(i))
        }
        if (typeof valueArray !== 'object') valueArray = [String(valueArray)]

        if (!this.fieldNames.multiple) {
          arr = treeFindPath(this.options, data => data[this.fieldNames.value] == valueArray[0], this.fieldNames)
        } else {
          valueArray.forEach((item) => {
            arr.push(treeFindPath(this.options, data => data[this.fieldNames.value] == item, this.fieldNames))
          })
        }
        this.cascaderValue = arr

        return
      },
      immediate: true
    },
  },
  computed: {
    newOptions() {
      var constructCascaderData = (extraMap) => {
        if (!extraMap.map) return []
        return extraMap.map(item => {
          var tempNods = {}
          tempNods = {
            value: item[this.fieldNames.value],
            label: item[this.fieldNames.label]
          }
          if (item[this.fieldNames.children] && item[this.fieldNames.children].length > 0) tempNods.children = constructCascaderData(item[this.fieldNames.children])
          return tempNods
        })
      }
      return constructCascaderData(this.options)
    },
  },
  methods: {
    handleChange(value) {
      var arr = []
      var that = this
      this.$nextTick(() => {
        if (value.length > 0) {
          value.forEach(item => {
            arr.push(item)
          });
        }
        arr = arr.length == 0 ? '' : String(arr[arr.length-1])
        that.$emit('input', arr)
        that.$emit('change', arr)
      })
    },
    isShowSearch(showSearch) {
      return showSearch ? { "show-search": { filter: this.filter } } : {}
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
  },
  mounted() {
  },
}
export var treeSelect = {
  template: `<a-tree-select
  ref="treeSelect"
  v-model="treeValue"
  @change="handleChange"
  tree-checkable
  :placeholder="placeholder"
  :disabled="disabled"
  :size="size"
  :allowClear="allowClear"
  :tree-data="options"
  :replace-fields="fieldNames"
  :treeNodeFilterProp="'title'"
  :dropdownStyle="{'max-height':'355px'}"
  
  ></a-tree-select>`,
  name: 'tree-select',
  props: {
    placeholder: {
      type: String,
      default: '请选择'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "default"
    },
    fieldNames: {
      type: Object,
      default: () => ({ title: 'name', key:'aid', value: 'aid', children: 'child' })
    },
    options: {
      type: Array,
      default: []
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      treeValue: []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.treeValue = val ? (val + '').split(',') : []
      },
      immediate: true
    },
  },
  computed: {
  },
  methods: {
    handleChange(value) {
      var arr = value.join(',')
      this.$emit('input', arr)
      this.$emit('change', arr)
    },
  },
  mounted() {
  },
}
export var editorForm = {
  template: `<div class="editor">
<div :id="'el' + id" style="text-align: left;width:100%;height:100%;position: relative;z-index: 0;"></div>
<div v-if="progressVisible" class="progress-box">
  <a-progress color="#409eff" type="circle" :percentage="percent"></a-progress>
</div>
</div>`,
  name: "editor-form",
  data() {
    return {
      editor: null, // 富文本示例
      editorContent: "", // 富文本内容
      progressVisible: false, //进度条的进度
      percent: 0,
      uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/upload',
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN),
        Accept: 'application/json, text/plain, */*'
      },
    };
  },
  props: ["value", "id", "disabled"], // 父组件传递过来的富文本默认内容
  methods: {
    // 初始化富文本
    initEditor() {
      // var E = window.wangEditor;
      var that = this;
      setTimeout(() => {
        this.editor = new E("#el" + this.id);
        // this.editor.config.customUploadImg
        // 禁用编辑功能
        // 代码高亮
        this.editor.highlight = hljs
        this.editor.$textElem.attr('contenteditable', false)
        this.editor.config.uploadImgServer = this.uploadImgUrl
        this.editor.config.uploadImgHeaders = this.headers
        this.editor.config.uploadFileName = 'file'
        this.editor.config.uploadImgHooks = {
          customInsert: function (insertImgFn, result) {
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            insertImgFn(result.url)
          }
        }
        this.editor.config.uploadVideoServer = this.uploadImgUrl
        this.editor.config.uploadVideoHeaders = this.headers
        this.editor.config.uploadVideoName = 'file'
        this.editor.config.uploadVideoHooks = {
          customInsert: function (insertVideoFn, result) {
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            insertVideoFn(result.data.url)
          }
        }
        // this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        //     handleUploadPlan(resultFiles[0], (res) => {
        //         var data = res;
        //         insertImgFn(data.path)
        //     })
        // }
        // this.editor.config.customUploadVideo = function (resultFiles, insertVideoFn) {
        //     handleUploadPlan(resultFiles[0], (res) => {
        //         var data = res;
        //         insertVideoFn(data.path)
        //     })
        // }
        // 监听富文本内容更改
        this.editor.config.onchange = (html) => {
          //
          this.editorContent = html;
          // 将内容发送给父组件
          this.$emit("input", html);
          this.$emit('change', html)
        };
        // 设置高度
        // this.editor.config.height = 500
        // 创建富文本实例
        this.editor.create();
        // 禁用编辑功能
        if (this.disabled) {
          this.editor.disable()
        }
        // 设置富文本内容
        this.editor.txt.html(this.value);
      });
      function handleUploadPlan(file, successFn, errorFn) {
        that.duration = 0;	// 视频、音频时长
        $.ajax({
          url: baseURL + `/index/oss/get?memberid=${window.member_id || '1899'}`,
          data: {},
          type: "GET",
          success: function (res) {
            var obj = JSON.parse(res); //接收请求数据
            // 判断是图片，压缩
            var fileName = file.name;
            var fileType = fileName.substring(fileName.lastIndexOf('.'));
            var fileAccept = file.type.split('/') && file.type.split('/')[0];

            if (['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp', '.psd', '.svg', '.tiff'].indexOf(fileType.toLowerCase()) > -1) {
              compressImg(file, function (res) {
                var fileData = dataURLtoFile(res, (file['name'] || 'a.png'))
                getFilePlan(obj, fileData)
              })
            } else if (['video', 'audio'].includes(fileAccept)) {
              var file_url = URL.createObjectURL(file);
              getVideoDuration(file_url, fileAccept).then((duration) => {
                that.duration = duration
                getFilePlan(obj, file)
              })
            } else {
              getFilePlan(obj, file)
            }
          },
          error(err) {
            if (typeof errorFn == 'function') errorFn(err)
          }
        });
        //前端分片上传安装包
        function getFilePlan(obj, fileInfo) {
          that.progressVisible = true
          that.percent = 0
          var getSuffix = function (fileName) {
            var pos = fileName.lastIndexOf(".");
            var suffix = "";
            if (pos != -1) {
              suffix = fileName.substring(pos);
            }
            return suffix;
          };
          //获取文件路径
          var file = fileInfo.name;
          //创建一个空对象实例
          var formData = new FormData();
          //获取当前时间戳加上获取的文件后缀 为文件名
          var joint = md5(new Date().getTime() + Math.floor(Math.random() * 10000))
          var filename = joint + getSuffix(file);
          //注意formData里append添加的键的大小写
          formData.append("key", obj.dir + filename); //存储在oss的文件路径
          formData.append("OSSAccessKeyId", obj.accessid); //accessKeyId
          formData.append("policy", obj.policy); //policy
          formData.append("Signature", obj.signature); //签名
          formData.append("callback", obj.callback); //签名
          //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
          formData.append("success_action_status", 200); //成功后返回的操作码
          formData.append("Content-Disposition", 'attachment;filename=' + file); //成功后返回的操作码
          formData.append("file", fileInfo);

          var xhrOnProgress = function (fun) {
            xhrOnProgress.onprogress = fun; //绑定监听
            //使用闭包实现监听绑
            return function () {
              //通过$.ajaxSettings.xhr();获得XMLHttpRequest对象
              var xhr = $.ajaxSettings.xhr();
              //判断监听函数是否为函数
              if (typeof xhrOnProgress.onprogress !== "function") return xhr;
              //如果有监听函数并且xhr对象支持绑定时就把监听函数绑定上去
              if (xhrOnProgress.onprogress && xhr.upload) {
                xhr.upload.onprogress = xhrOnProgress.onprogress;
              }
              return xhr;
            };
          };
          $.ajax({
            type: "POST",
            data: formData,
            processData: false, //这里默认为true,必须手动改为false
            contentType: false, //这里必须手动改为false,避免出现解析错误
            cache: false, //缓存必须改为false,保证每次都能读取最新数据
            url: obj.host,
            xhr: xhrOnProgress(function (e) {
              that.percent = parseInt(e.loaded * 100 / e.total); //计算百分比
            }),
            success: function (res) {
              var path = obj.host + '/' + obj.dir + filename; // url地址
              var fileInfoData = {
                name: fileInfo.name,  // 文件名称
                // size: fileInfo.size,  // 文件大小
                driver: 'oss',  // 文件驱动
                path: path,
                thumb: path, //富文本不需要封面图
                mime: fileInfo.type,       // 文件类型
                // ext: fileInfo.name.substring(fileInfo.name.lastIndexOf(".") + 1),            //文件后缀
                // md5: joint,             // md5
              }
              // _this.form.file_list.push({ 'name': fileInfoData.name, 'url': fileInfoData.path })
              setTimeout(() => {
                that.percent = 0
                that.progressVisible = false
              }, 300)
              if (that.duration > 0) fileInfoData['duration'] = that.duration
              if (typeof successFn == 'function') successFn(fileInfoData)
            },
            error: function (err) {
              setTimeout(() => {
                that.percent = 0
                that.progressVisible = false
              }, 300)
              if (typeof errorFn == 'function') errorFn(err)
            },
          });
        };
      }


      // 获取视频时长
      function getVideoDuration(url, dom = 'video') {
        return new Promise((reslove) => {
          var video = document.createElement(dom);
          video.preload = 'metadata';
          video.src = url;
          video.onloadedmetadata = () => {
            reslove(parseInt((video.duration).toString(), 10));
            video = null;
          };
        });
      }
    },
  },

  mounted() {
    this.initEditor();
  },
}
export var scrollList = {
  template:` 
  <div class="list-wrap">
  <div class="content" ref="list" @scroll="onScroll">
    <slot></slot>
  </div>
</div>`,
  name: 'scroll-list',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
  },
  methods:{
    onScroll(){
      var obj = this.$refs.list
      if (this.disabled) { return }
      if (Math.abs(obj.clientHeight + obj.scrollTop === obj.scrollHeight)<=20) {
        this.$emit("loadmore");
      }
    }
  }
}
export var messageReply  = {
  template:` <div class='flex justify-between align-center'>
  <a-input :disabled="disabled" @input="inputReply" @keyup.enter="recordReply(inputValue)" v-model="inputValue" class='flex-sub' placeholder="请输入回复内容(Enter)" :max-length="240" size="large" :allow-clear='true' ></a-input>
  <a-button type="primary" size="large" style="margin-left:5px" :style="{background: background}" :disabled="disabled" @click="recordReply(inputValue)">{{btntext}}</a-button>
  <a-modal :visible="treeVisible" :maskClosable="false" title="选择@目标" @cancel="treeVisible = false" @ok="confirm">
    <a-select
      ref="treeSelect"
      mode="multiple"
      style="width: 100%"
      placeholder="请选择"
      v-model="treeValue"
    >
      <a-select-option v-for="item in treeList" :key="item.aid">
        {{ item.name}}
      </a-select-option>
    </a-select>
  </a-modal>
  </div>`,
  name: 'messageReply',
  data() {
    return {
      inputValue: '',
      treeValue:[],
      treeVisible:false,
      checkValue:''
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    btntext: {
      type: String,
      default: '回复'
    },
    background: {
      type: String,
      default: 'rgb(71, 129, 245)'
    },
    aid: {
      type: Number,
      default: 0
    },
    value: {
      type: String,
      default: ''
    },
    treeList: {
      type: Array,
      default: []
    },
  },
  watch: {
    value: {
      handler(val) {
        this.inputValue =val
      },
      immediate: true
    },
  },
  mounted(){
  },
  methods:{
    inputReply(e) {
      var value = e.target.value;
      var valueLast = value.substr(value.length - 1, 1);
      if (valueLast == '@' && e.inputType != 'deleteContentBackward') {
        this.treeVisible = true
        this.treeValue = this.checkValue?this.checkValue.split(',').map(Number):[]
      }
    },
    recordReply(val){
      this.$emit('input','')
      this.inputValue = this.checkValue = ''
      this.$emit('update:reply_data',{content:val,pid:this.aid,ids:this.checkValue})
      this.$emit('reply')
    },
    confirm(){
      this.checkValue =  this.treeValue.join(',')
      this.treeVisible = false
      var staffIds = ',' + this.checkValue + ',';
      var staffNames = ''
      for (var i =0; i< this.treeList.length-1; i++) {
        var value = ',' + this.treeList[i]['aid'] + ',';
        var name = this.treeList[i]['name']
        if (staffIds.indexOf(value) != -1 && this.inputValue .indexOf('@' + name)==-1) {
          if (staffNames) {
            staffNames += '@' + name + ' ';
          } else {
            staffNames += name + ' ';
          }
        }
      }
      this.inputValue += staffNames
    }
  }
}
export var handSignature  = {
  template:` <div class=''>
  <div @click="showSign">
  <a-button type="primary" icon="edit"> {{imgsrc?'重写签名':'打开签字版'}}</a-button>
  </div>
  <div class="img-item-info" v-if="imgsrc">
      <img class="img-signature" :src="imgsrc"></img>
      <span class="actions-signature align-center">
      <a-icon @click="handlePrediv(imgsrc)" type="eye-o" />
      <a-icon @click="installImage(imgsrc)" type="download" />
      <a-icon @click="handleDeleteImage" type="delete" />
      </span>
  </div>
  <a-modal :visible="signVisible" :width="width" :maskClosable="false" title="请签名"  @cancel="cancle_sign">
    <div class="container" :style="{ width: '100%', height: height + 'px' }">
        <div class="vue-signature" :style="{ width: '100%', height: height + 'px' }">
            <canvas
            :id="id"
            :width="width-50"
            :height="height"
            @mousedown.prevent="mouseDown"
            @mouseup.prevent="mouseUp"
            @mouseout.prevent="mouseUp"
            @mousemove.prevent="mouseMove"
            @touchstart.prevent="mouseDown"
            @touchmove.prevent="mouseMove"
            @touchend.prevent="mouseUp"
            @touchcancel.prevent="mouseUp">
            Your browser doesn't support canvas
          </canvas>
        </div>
    </div>
    <div slot="footer" class="dialog-footer flex justify-between align-center">
        <div class="flex align-center">
          <a-tag :color="item" v-for="item in colors" :key="item" @click="changeColor(item)"
          :class="{ active: strokeColor === item,'color-item':true }"></a-tag>
          <a-icon @click="clear" style="font-size:24px" type="sync" /> 
        </div>
        <div>
          <a-button @click="cancle_sign">取 消</a-button>
          <a-button type="primary" @click="confirm_sign">完成</a-button>
        </div>
    </div>
  </a-modal>
  <a-modal :visible="predivVisible" :footer="null" @cancel="predivVisible = false">
    <a-icon slot="closeIcon" type="close-circle" style="position: absolute;right: 0px;top: -28px;font-size: 20px;color: #fff;" />
    <img alt="example" style="width: 100%" :src="predivImage" />
  </a-modal>
  <div v-if="progressVisible" class="progress-box">
    <a-progress color="#409eff" type="circle" :percentage="percent"></a-progress>
  </div>
  </div>`,
  name: 'hand-signature',
  data() {
    return {
      predivImage: '',
      predivVisible:false,
      signVisible:false,
      id:'signature-' + guid(5),
      isMouseDown: false,
      lastLocation: {
        x: 0,
        y: 0
      },
      log: [],
      empty: true,
      strokeColor: "#161823",
      strokeSize: 2,
      signature: '',
      colors: ['#161823', '#3b2e7e', '#4b5cc4', '#4c8dae', '#c3272b', '#FF7F00', '#eacd76', '#40de5a', '#fafafa'],
      imgsrc:"",
      progressVisible: false, //进度条的进度
      percent: 0,
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 500
    },
    width: {
      type: Number,
      default: 700
    }
  },
  watch: {
    value: {
      handler(val) {
        this.imgsrc =val
      },
      immediate: true
    },
  },
  mounted(){
  },
  methods:{
    showSign() {
      this.signVisible = true
      this.$nextTick(()=>{
        this.init()
      })
    },
    handlePrediv(file) {
      this.predivImage = file
      this.predivVisible = true
    },
    handleDeleteImage() {
      this.imgsrc = ''
    },
    /**
     * initialization
     */
    init () {
      this.canvas = document.getElementById(this.id)
      this.context = this.canvas.getContext('2d')
    },
    /**
     * Clear canvas
     */
    clear () {
      this.context.clearRect(0, 0, this.width, this.height)
      this.empty = true
      this.signature = ''
    },

    /**
     * coordinate transformation
     * @param x
     * @param y
     * @returns {{x: number, y: number}}
     */
    coordinateTransfer (x, y) {
      var box = this.canvas.getBoundingClientRect()
      return {
        x: Math.round(x - box.left),
        y: Math.round(y - box.top)
      }
    },
    /**
     * Start writing
     * @param e
     */
    mouseDown (e) {
      var clientX = e.clientX || e.changedTouches[0].clientX
      var clientY = e.clientY || e.changedTouches[0].clientY
      this.isMouseDown = true
      this.lastLocation = this.coordinateTransfer(clientX, clientY)
    },
    /**
     * Pause writing
     * @param e
     */
    mouseUp (e) {
      this.isMouseDown = false
    },
    /**
     * Writing
     * @param e
     */
    mouseMove (e) {
      if (this.isMouseDown) {
        var clientX = e.clientX || e.changedTouches[0].clientX
        var clientY = e.clientY || e.changedTouches[0].clientY
        var currentLocation = this.coordinateTransfer(clientX, clientY)
        var context = this.context
        var lastLocation = this.lastLocation
        // draw
        context.beginPath()
        context.moveTo(lastLocation.x, lastLocation.y)
        context.lineTo(currentLocation.x, currentLocation.y)
        context.strokeStyle = this.strokeColor
        context.lineWidth = Number(this.strokeSize)
        context.lineCap = 'round'
        context.lineJoin = 'round'
        context.stroke()
        this.lastLocation = currentLocation
        this.empty = false
      }
    },
    /**
     * Get Base64 data of signature image
     * @param format
     * 1. image/png (default)
     * 2. image/jpeg
     * 3. image/svg+xml
     * @returns {string}
     */
    getSignature (format) {
      format = format || 'image/png'
      this.signature = this.canvas.toDataURL(this.format, 1)
    },
    changeColor (color) {
      this.strokeColor = color
    },
    cancle_sign(){
      this.clear()
      this.signVisible = false
    },
    confirm_sign(){
      if(this.empty){
        return message.error('手写签名不能为空')
      }
      if(!this.signature){
        this.signature = this.canvas.toDataURL(this.format, 1)
      }
      var imgFile = dataURLtoFile(this.signature,'签名图片.png')
      var that = this
      compressImg(imgFile,(base64)=>{
        imgFile = dataURLtoFile(base64,'签名图片.png')
        // oss上传
        getUploadToOSSOptions((obj)=>{getFilePlan(obj, imgFile)})
      })
      //前端分片上传安装包
      function getFilePlan(obj, fileInfo) {
        that.progressVisible = true
        that.percent = 0
        var getSuffix = function (fileName) {
          var pos = fileName.lastIndexOf(".");
          var suffix = "";
          if (pos != -1) {
            suffix = fileName.substring(pos);
          }
          return suffix;
        };
        //获取文件路径
        var file = fileInfo.name;
        //创建一个空对象实例
        var formData = new FormData();
        //获取当前时间戳加上获取的文件后缀 为文件名
        var joint = md5(new Date().getTime() + Math.floor(Math.random() * 10000))
        var filename = joint + getSuffix(file);
        //注意formData里append添加的键的大小写
        formData.append("key", obj.dir + filename); //存储在oss的文件路径
        formData.append("OSSAccessKeyId", obj.accessid); //accessKeyId
        formData.append("policy", obj.policy); //policy
        formData.append("Signature", obj.signature); //签名
        formData.append("callback", obj.callback); //签名
        //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append("success_action_status", 200); //成功后返回的操作码
        formData.append("Content-Disposition", 'attachment;filename=' + file); //成功后返回的操作码
        formData.append("file", fileInfo);

        var xhrOnProgress = function (fun) {
          xhrOnProgress.onprogress = fun; //绑定监听
          //使用闭包实现监听绑
          return function () {
            //通过$.ajaxSettings.xhr();获得XMLHttpRequest对象
            var xhr = $.ajaxSettings.xhr();
            //判断监听函数是否为函数
            if (typeof xhrOnProgress.onprogress !== "function") return xhr;
            //如果有监听函数并且xhr对象支持绑定时就把监听函数绑定上去
            if (xhrOnProgress.onprogress && xhr.upload) {
              xhr.upload.onprogress = xhrOnProgress.onprogress;
            }
            return xhr;
          };
        };
        $.ajax({
          type: "POST",
          data: formData,
          processData: false, //这里默认为true,必须手动改为false
          contentType: false, //这里必须手动改为false,避免出现解析错误
          cache: false, //缓存必须改为false,保证每次都能读取最新数据
          url: obj.host,
          xhr: xhrOnProgress(function (e) {
            that.percent = parseInt(e.loaded * 100 / e.total); //计算百分比
          }),
          success: function (res) {
            var path = obj.host + '/' + obj.dir + filename; // url地址
            that.$emit('input',path)
            that.clear()
            setTimeout(() => {
              that.percent = 0
              that.progressVisible = false
              that.signVisible = false
            }, 300)
          },
          error: function (err) {
            setTimeout(() => {
              that.percent = 0
              that.progressVisible = false
            }, 300)
          },
        });
      };
    }
  }
}
export var qiunDataCharts = {
  template:`<qiun-vue-ucharts 
  type="column"
  :opts="opts"
  :chartData="chartDataCom"
/>`,
  name: 'qiun-data-charts',
  data() {
    return {
      uChartsInstance:{},
      cId:''
    };
  },
  components: {
    qiunVueUcharts
  },
  props: {
    opts: {
      type: Object,
      default: {}
    },
    type: {
      type: String,
      default: 'column'
    },
    chartData: {
      type: Object,
      default: {}
    },
  },
  computed: {
    optsCom() { JSON.parse(JSON.stringify(this.opts))},
    chartDataCom() {
      return JSON.parse(JSON.stringify(this.chartData))
    }
  },
  watch: {
  },
  mounted(){
  },
  methods:{

  }
}
/* 全局组件结束 */
